












import Vue from "vue"
import invariant from "invariant"

import AudioPlayer from "@/components/GroupTeams/Misc/AudioPlayer.vue"

import { AudioEntry, AudioCollection } from "@/modules/audio/types"

enum Subscription {
  Audios,
  Current,
  Loop
}

export default Vue.extend({
  name: "ClientAudioPlayer",
  components: {
    AudioPlayer
  },
  data() {
    return {
      audios: [] as AudioEntry[],
      loop: false,
      current: null as AudioEntry | null,
      previous: null as AudioEntry | null
    }
  },
  async created() {
    this._unsunscribe = {
      [Subscription.Audios]: null,
      [Subscription.Current]: null,
      [Subscription.Loop]: null
    }

    this.subscribe()
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  computed: {
    isPlay() {
      return this.current?.status === "play"
    },
    clientID(): string {
      return this.$store.state.auth.clientID
    },
    model: {
      get(): string | undefined {
        return this.current?.id
      },
      set(value: string) {
        const audio = this._getAudioByID(value)
        if (audio !== undefined) {
          this.setCurrent({ ...audio, status: "play" })
        }
      }
    }
  },
  methods: {
    subscribe() {
      this.$services.get("audio").then(service => {
        this._unsunscribe[Subscription.Audios] = service.subscribe(
          AudioCollection.Client,
          { clientID: this.clientID },
          audios => {
            this.audios = audios
          }
        )

        this._unsunscribe[Subscription.Current] = service.subscribeToCurrent(
          this.clientID,
          current => {
            this.current = current
          }
        )

        this._unsunscribe[Subscription.Loop] = service.subscribeToLoop(
          this.clientID,
          value => {
            this.loop = value
          }
        )
      })
    },
    unsubscribe() {
      for (const key in this._unsunscribe) {
        if (this._unsunscribe[key]) {
          this._unsunscribe[key]()
        }
      }
    },
    onStop() {
      this.setCurrent({ ...this.current, status: "pause" })
    },
    onPlay() {
      this.setCurrent({ ...this.current, status: "play" })
    },
    setCurrent(audio: AudioEntry | null) {
      return this.$services.get("audio").then(service => {
        service.setCurrent(this.clientID, audio ? { ...audio } : null)
      })
    },
    onLoopUpdate(value: boolean) {
      return this.$services
        .get("audio")
        .then(service => service.setLoop(this.clientID, value))
    },
    onSelect(audioID: string) {
      this.audioID = audioID
      // this.updateClientCurrentAudioTrack() ???
    },
    _getAudioByID(audioID: string, collection: AudioCollection) {
      const audio = this.audios.find(audio => audio.id === audioID)
      invariant(audio, `Audio with id "${audioID}" not found`)
      return audio
    }
  }
})
