var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AudioPlayer", {
    attrs: { items: _vm.audios, loop: _vm.loop, active: _vm.isPlay },
    on: { stop: _vm.onStop, play: _vm.onPlay, "update:loop": _vm.onLoopUpdate },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }